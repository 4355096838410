function handleMouseClick(e) {
  window.removeEventListener("click", handleMouseClick);
  document.body.classList.add("no-outline");
  window.addEventListener("keydown", handleTabPress);
}

function handleTabPress(e) {
  if (e.keyCode === 9) {
    window.removeEventListener("keydown", handleTabPress);
    document.body.classList.remove("no-outline");
    window.addEventListener("click", handleMouseClick);
  }
}

document.body.classList.add("no-outline");
window.addEventListener("keydown", handleTabPress);
