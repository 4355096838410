<template>
  <aside class="top-nav-sidebar" v-bind:class="{ 'is-active': sidebarOpen }">
    <ul class="top-nav-sidebar-header-list">
      <li :class="{ 'is-active': isMenu }">
        <a href="#" @click="doShowMenu($event, 'menu')">Meny</a>
      </li>
      <li v-for="item in topnavComputed" v-bind:key="item.id" :class="{ 'is-active': !isMenu }">
        <a href="#" @click="doShowMenu($event, 'article')">{{ item.header }}</a>
      </li>
    </ul>

    <ul class="top-nav-sidebar-list" v-if="!isMenu">
      <li v-for="item in blocknavComputed" v-bind:key="item.id" :class="{ 'is-active': doSetActive(item) }">
        <a data-ga-event-action="Klick" :data-ga-event-label="item.header" data-ga-event-page="Meny - Artiklar" v-bind:href="item.url" v-bind:target="item.link_target" class="top-nav-sidebar-list__link">
          <img v-bind:src="item.image.filename" width="200" height="200" loading="lazy" v-bind:alt="item.image.alt" />
          <div>
            <h3 class="mt-0">{{ item.subheader }}</h3>
            <h2>{{ item.header }}</h2>
          </div>
        </a>
      </li>
    </ul>

    <ul class="top-nav-sidebar-menu-list" v-if="isMenu">
      <li v-for="item, index in menunavComputed" v-bind:key="item.id" :class="{ 'is-active': doSetActive(item), 'top-nav-sidebar-menu-list__line' : index === 2  }">
        <a data-ga-event-action="Klick" :data-ga-event-label="item.header" data-ga-event-page="Meny" v-bind:href="item.url" v-bind:target="item.link_target">
          <h2>{{ item.header }}<span></span></h2>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
import { computed, ref } from "vue";
import { sidebarOpen } from "./v-top-nav.vue";


export default {
  props: {
    topnav: {
      type: Object,
    },
    blocknav: {
      type: Object,
    },
    menunav: {
      type: Object,
    },
  },
  setup(props) {
    const blocknavComputed = computed(() => props.blocknav);
    const topnavComputed = computed(() => props.topnav);
    const menunavComputed = computed(() => props.menunav);
    const isMenu = ref(true);

    // if (window.location.pathname !== "/")
    //   isMenu.value = false;

    const doShowMenu = (e, alt) => {
      e.preventDefault();
          
      if (alt === "menu") {
        isMenu.value = true;
      }
      else {
        isMenu.value = false;
      }
    }

    return {
      sidebarOpen,
      blocknavComputed,
      topnavComputed,
      menunavComputed,
      isMenu,
      doShowMenu
    };
  },
  methods: {
    doSetActive(item) {
      var currentUrl = item.url;
      
      if (currentUrl.length !== 1 && !currentUrl.endsWith('/')) {
        currentUrl += "/";
      }

      return currentUrl === location.pathname;
    },
  },

};
</script>
