import { createApp, ref } from 'vue';
import TopNavComponent from './components/v-top-nav.vue';
import TopNavAsideComponent from './components/v-top-nav-aside.vue';
import TelegramWidgetComponent from './components/v-telegram-widget.vue';
import SubscribeFormComponent from './components/v-subscribe-form.vue';
import VPaginate from './components/v-paginate/Paginate.vue'
import VCustomPaginate from './components/v-paginate/v-paginate-custom.vue'

//import 'images/favicon.ico';

// General scripts
import "./utils";
import "./outline";
import "./ga-tracking";
import AOS from 'aos';
AOS.init();

const app = createApp({
  compilerOptions: {
    delimiters: ['${', '}'] // {{ }} in vue single page components
  }
});

app.component('v-top-nav', TopNavComponent);
app.component('v-top-nav-aside', TopNavAsideComponent);
app.component('v-telegram-widget', TelegramWidgetComponent);
app.component('v-subscribe-form', SubscribeFormComponent);
app.component('v-paginate', VPaginate);
app.component('v-paginate-custom', VCustomPaginate);

app.mount('#app');