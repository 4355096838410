<template>
  <div class="widget-topic" ref="telegramWiget"></div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    topic: {
      type: String,
    },
    position: {
      type: String,
    },
  },
  mounted() {
    var newScriptTag = document.createElement("script");
    newScriptTag.type = "text/javascript";
    newScriptTag.src = this.src; //"https://telegram.org/js/telegram-widget.js?14";
    newScriptTag.async = true;

    if (this.position === "top") {
      newScriptTag.setAttribute("data-telegram-post", this.topic); //"torbentest/20");
      newScriptTag.setAttribute("data-width", "100%");
      newScriptTag.setAttribute("data-color", "343638");
      newScriptTag.setAttribute("data-dark-color", "FFFFFF");
    } else { // bottom
      newScriptTag.setAttribute("data-telegram-discussion", this.topic); //"torbentest/20");
      newScriptTag.setAttribute("data-comments-limit", "5");
      newScriptTag.setAttribute("data-colorful", "1");
      newScriptTag.setAttribute("data-color", "5EAA94");
    }

    this.$refs.telegramWiget.appendChild(newScriptTag);
  },
};
</script>


<style>
</style>


