<template>
  <v-paginate :page-count="pageCount" :value="value" :page-range="4" :prev-text="'« Föregående'" :next-text="'Nästa »'" :click-handler="clickHandler"></v-paginate>
</template>
<script>

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    url: {
      type: String,
      default: '/',
      required: true
    },
    pageCount: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const clickHandler = function(page) {
      if (page == 1) {
        window.location.href = props.url;
        return;
      }
        
      if (props.url.endsWith('/'))
      {
        window.location.href = `${props.url}${page}`;
        return;
      }

      window.location.href = `${props.url}/${page}`;
    };

    return {  
      clickHandler
    }
  },
}
</script>
