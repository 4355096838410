<template>
  <form
    v-on:submit.prevent
    v-bind:id="typeform"
    v-bind:name="typeform"
    v-bind:data-autopilot-anywhere="autopilot"
    v-bind:data-ga-event-action="action"
    v-bind:data-ga-event-label="eventlabel"
    v-bind:data-ga-event-page="eventpage"
    action="">
    <div class="mt-3 row gx-1">
      <div class="col-12 col-md-8">
        <label for="datadrivet_subscriber_form_email" class="visually-hidden">E-postadress</label>
        <input
          type="text"
          class="form-control"
          placeholder="E-postadress"
          name="datadrivet_subscriber_form_email"
          id="datadrivet_subscriber_form_email"
        />
      </div>
      <div class="col-12 col-md-4 mt-1 mt-md-0">
        <input
          v-on:click="submitForm"
          type="submit"
          class="btn btn-primary" v-bind:class='buttoncolor'
          name="datadrivet_subscriber_form_submit"
          v-bind:value="buttontext"
        />
      </div>
    </div>
    <div class="site-color--pink" v-if="isSubmitted" v-cloak>
      Va kul - du har ett välkomstmejl i din inbox!
    </div>
    <div class="site-color--pink" v-if="!isValidEmail" v-cloak>Ange en giltig e-postadress!</div>
  </form>
</template>

<script>
//import { ref, onMounted, computed } from "vue";

export default {
  props: {
    buttoncolor: {
      type: String,
    },    
    buttontext: {
      type: String,
    },
    errormsg: {
      type: String,
    },
    successmsg: {
      type: String,
    },
    autopilot: {
      type: String,
    },
    typeform: {
      type: String,
    },    
    eventlabel: {
      type: String,
    },   
    eventpage: {
      type: String,
    },   
  },
  data: function () {
    return {
      isSubmitted: false,
      isValidEmail: true,
      isValidForm: true,
      isSubmittedContact: false,
      isValidEmailContact: true,
      isValidFormContact: true,
      action: "Skicka",
    };
  },
  // setup(props, context) {
  //   const subscribe = ref(null);
  //   // const hasFinished = false;

  //   onMounted(() => {
  //     console.log("mounted!");
  //     console.log(props.test);
  //   });

  //   return {
  //     subscribe,
  //     props,
  //   };
  // },
  methods: {
    submitForm: function () {
      var email = document.getElementById("datadrivet_subscriber_form_email");

      if (email.value.indexOf("@") === -1) {
        this.isValidEmail = false;
        this.isSubmitted = false;
      } else {
        this.isSubmitted = true;
        this.isValidEmail = true;
        email.value = "";
      }
    },

    scrollToEmail: function () {
      var element_to_scroll_to = document.getElementById(
        "datadrivet_subscriber_form_email"
      );
      if (element_to_scroll_to !== null) {
        element_to_scroll_to.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        setTimeout(function () {
          element_to_scroll_to.focus();
        }, 1000);
      }
    },

    submitContactForm: function () {
      var email = document.getElementById("datadrivet_contact_form_email");
      var message = document.getElementById("datadrivet_contact_form_text");

      this.isSubmittedContact = false;

      if (email.value.indexOf("@") === -1) {
        this.isValidEmailContact = false;
      } else {
        this.isValidEmailContact = true;
      }

      if (message.value === "" && this.isValidEmailContact) {
        this.isValidFormContact = false;
      } else if (message.value !== "" && this.isValidEmailContact) {
        this.isValidFormContact = true;
      }

      if (this.isValidEmailContact && this.isValidFormContact) {
        email.value = "";
        message.value = "";
        this.isValidFormContact = true;
        this.isSubmittedContact = true;
      }
    },
  },
};
</script>


<style>
</style>