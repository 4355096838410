window.addEventListener('load', () => {
  var currentHash = window.location.hash;
  if (currentHash.indexOf('#') !== -1 && currentHash.indexOf('/') === -1) {
      var arrAnchor = currentHash.split('#');
      JumpToHash(arrAnchor[1]);
  }
});

document.body.addEventListener("click", function (e) {

  var obj = e.target;

  if (obj.tagName === "A" && obj.href.indexOf('#') !== -1) {

      if (obj.getAttribute('target') === null) { // open in same window

          var hash = obj.getAttribute('href');
          if (hash === undefined) {
              hash = obj.getAttribute('data-href');
          }

          var arrAnchor = hash.split('#');

          if (document.getElementById(arrAnchor[1]) !== null && (location.pathname === obj.pathname)) { // is anchor within the page
              e.preventDefault();
              JumpToHash(arrAnchor[1]);
              history.pushState({}, '', obj.href);
          }

      }
  }
});

window.JumpToHash = function (hashName) {

  var element_to_scroll_to = document.getElementById(hashName);
  if (element_to_scroll_to !== null) {
      element_to_scroll_to.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

      // now account for fixed header
      var scrolledY = window.scrollY;

      var topOffset = 0;

      if (scrolledY) {
          window.scroll(0, scrolledY - topOffset);
      }
  }

};